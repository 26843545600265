/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { WithdrawalProp } from "./types";

const initialStateValue: WithdrawalProp = {
  detailedWithdrawal: [],
  detailedWithdrawalLoading: true,
  withdrawals: [],
  withdrawalsLoading: true,
  champWithdrawals: [],
  champWithdrawalsLoading: true,
  champDetailedWithdrawal: [],
  champDetailedWithdrawalLoading: true,
};

export const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState: { value: initialStateValue },
  reducers: {
    setWithdrawal: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setWithdrawal } = withdrawalSlice.actions;

export default withdrawalSlice.reducer;
