/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { DashboardProp } from "./types";
import dayjs from "dayjs";

const today = dayjs().add(1, "day").format("YYYY-MM-DD");

const initialStateValue: DashboardProp = {
  dashboard: {
    graph: [],
    mostReview: [],
    customerTotal: 0,
    establishmentTotal: 0,
    champTotal: 0,
    orders: 0,
    pending: 0,
    confirmed: 0,
    processing: 0,
    handover: 0,
    pickedUp: 0,
    delivered: 0,
    cancelled: 0,
    sumDelivered: 0,
  },
  dashboardLoading: true,
  date: {
    start: "",
    end: today,
  },
  dateFilter: "overall",
  countryFilter: "",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: { value: initialStateValue },
  reducers: {
    setDashboard: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetDashboard: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setDashboard, resetDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
