/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { CustomerProp } from "./types";

const initialStateValue: CustomerProp = {
  customers: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  customersLoading: true,
  detailedCustomer: [],
  detailedCustomerLoading: true,
  customerOrder: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  customerOrderLoading: true,
  customerRide: [],
  customerRideLoading: true,
  customerWallet: {
    user: {
      id: "",
      wallet_balance: 0,
    },
    wallet: [],
    wallet_transactions: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      first_page: 0,
      first_page_url: "",
      last_page_url: "",
      next_page_url: undefined,
      previous_page_url: undefined,
      data: [],
    },
  },
  customerWalletLoading: true,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState: { value: initialStateValue },
  reducers: {
    setCustomer: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetCustomer: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setCustomer, resetCustomer } = customerSlice.actions;

export default customerSlice.reducer;
