/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { CouponPropType } from "./types";

const initialStateValue: CouponPropType = {
  coupons: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: undefined,
    previous_page_url: undefined,
    data: [],
  },
  couponsLoading: true,
};

export const couponsSlice = createSlice({
  name: "coupon",
  initialState: { value: initialStateValue },
  reducers: {
    setCoupons: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetCoupons: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setCoupons, resetCoupons } = couponsSlice.actions;

export default couponsSlice.reducer;
