/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { ChampPropType } from "./types";

const initialStateValue: ChampPropType = {
  rideOrders: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  rideOrdersLoading: true,
  detailedRideOrder: [],
  drivers: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  driversLoading: true,
  detailedRideOrderLoading: true,
  detailedDriver: [],
  detailedDriverTransactions: [],
  detailedDriverTransactionsLoading: true,
  detailedDriverLoading: true,
  detailedDriverKyc: [],
  driverKyc: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  driverTax: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  driverTaxLoading: true,
  driverKycLoading: true,
  detailedDriverKycLoading: true,
};

export const champSlice = createSlice({
  name: "champ",
  initialState: { value: initialStateValue },
  reducers: {
    setChamp: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetChamp: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setChamp, resetChamp } = champSlice.actions;

export default champSlice.reducer;
