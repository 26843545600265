/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { AddonPropType } from "./types";

const initialStateValue: AddonPropType = {
  addons: [],
  addonsLoading: true,
};

export const addonSlice = createSlice({
  name: "addon",
  initialState: { value: initialStateValue },
  reducers: {
    setAddon: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetAddon: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setAddon, resetAddon } = addonSlice.actions;

export default addonSlice.reducer;
