/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { RequestPropType } from "./types";

const initialStateValue: RequestPropType = {
  requests: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  requestsLoading: true,
  detailedRequest: [],
  detailedRequestLoading: true,
};

export const tabRequestSlice = createSlice({
  name: "tabrequest",
  initialState: { value: initialStateValue },
  reducers: {
    setTabRequest: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetTabRequest: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setTabRequest, resetTabRequest } = tabRequestSlice.actions;

export default tabRequestSlice.reducer;
