import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "./router/Router";
import { IRootState } from ".";

import Profile from "./utils/apiServices/profile";
import { login } from "./features/login/reducer";
import RootLoading from "./components/RootLoading/RootLoading";
import { generateToken, zim } from "./utils/chat";
import { setChat } from "./features/chat/reducer";

function App() {
  const dispatch = useDispatch();
  const [rootLoading, setRootLoading] = useState(true);
  const user = useSelector((state: IRootState) => state.user.value);
  const { loggedIn } = user;
  // access token
  const accessToken =
    localStorage.getItem("accessToken") ||
    sessionStorage.getItem("accessToken") ||
    "";

  // Get Profile
  useEffect(() => {
    if (accessToken && !loggedIn) {
      Profile.getProfile()
        .then((res) => {
          if (res.success) {
            const { data } = res;

            const localToken = localStorage.getItem("accessToken");
            // Dispatch Login
            dispatch(
              login({
                created_at: data.created_at,
                id: data.id,
                first_name: data.first_name,
                last_name: data.last_name,
                phone: data.phone,
                total_commission_earning: data.total_commission_earning,
                digital_received: data.digital_received,
                manual_received: data.manual_received,
                delivery_charge: data.delivery_charge,
                email: data.email,
                image: data.image,
                remember_token: data.remember_token,
                role_id: data.role_id,
                zone_id: data.zone_id,
                updated_at: data.updated_at,
                loggedIn: true,
                remember_me: !!localToken,
                access_token: accessToken,
              })
            );
            const userId = "1";
            const userName = "Admin";
            const newUserDetails = { userName, userID: userId };
            zim
              .login(newUserDetails, generateToken(userId, 0))
              .then(function () {
                dispatch(
                  setChat({
                    isLogged: true,
                    isLoading: false,
                  })
                );
              });

            setRootLoading(false);
          } else {
            setRootLoading(false);
            // notify("error", `${res.message}`);
          }
        })
        .catch(() => {
          setRootLoading(false);
        });
    } else {
      setRootLoading(false);
    }
    // eslint-disable-next-line
  }, []);
  return <div className="">{rootLoading ? <RootLoading /> : <Router />}</div>;
}

export default App;
