/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { EmployeePropType } from "./types";

const initialStateValue: EmployeePropType = {
  employeeRoles: [],
  employeeRolesLoading: true,
  employeeRole: undefined,
  employeeRoleLoading: true,

  employees: [],
  employeesLoading: true,
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState: { value: initialStateValue },
  reducers: {
    setEmployee: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetEmployee: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { resetEmployee, setEmployee } = employeeSlice.actions;

export default employeeSlice.reducer;
