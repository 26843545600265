/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { ItemsPropType } from "./types";

const initialStateValue: ItemsPropType = {
  items: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: undefined,
  },
  itemsLoading: true,
  singleItem: [],
  singleItemLoading: true,
};

export const itemSlice = createSlice({
  name: "item",
  initialState: { value: initialStateValue },
  reducers: {
    setItems: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetItems: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setItems, resetItems } = itemSlice.actions;

export default itemSlice.reducer;
