/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { EstablishmentProp } from "./types";

const initialStateValue: EstablishmentProp = {
  establishmentItem: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  establishmentItemLoading: true,
  establishment: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  establishmentOrders: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  establishmentReview: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  establishmentTax: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  pendingEstablishmentsKYCs: {
    total: 0,
    data: [],
  },
  singlePendingEstablishmentKYC: undefined,
  establiahmentTaxLoading: true,
  establishmentReviewLoading: true,
  establishmentOrdersLoading: true,
  establishmentLoading: true,
  singleEstablishment: [],
  singleEstablishmentLoading: true,
  pendingEstablishmentsKYCLoading: true,
  singlePendingEstablishmentKYCLoading: true,
};

export const establishmentSlice = createSlice({
  name: "estbliahment",
  initialState: { value: initialStateValue },
  reducers: {
    setEstablishment: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetEstablishment: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setEstablishment, resetEstablishment } =
  establishmentSlice.actions;

export default establishmentSlice.reducer;
