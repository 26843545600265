/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { CategoryProp } from "./types";

const initialStateValue: CategoryProp = {
  categories: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  categoriesLoading: true,
};

export const categorySlice = createSlice({
  name: "category",
  initialState: { value: initialStateValue },
  reducers: {
    setCategory: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetCategory: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setCategory, resetCategory } = categorySlice.actions;

export default categorySlice.reducer;
