import baseURL from "../baseUrl";
import getAccessToken from "../../accessToken";
import {
  ChangePasswordParameters,
  UpdatePasswordParameters,
  UpdateProfileParameters,
} from "./types";

class Profile {
  async getProfile() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/admin/profiles/get-profile`, {
      method: "get",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.json();
  }
  async updateProfile(data: UpdateProfileParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/vendor/profile-update`, {
      method: "put",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
  async updatePassword(data: UpdatePasswordParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/vendor/update-password`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
  async changePasswords(data: ChangePasswordParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/user/profile/changePassword`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
}

const instance = new Profile();

export default instance;
