import userReducer from "./features/login/reducer";
import itemReducer from "./features/items/reducer";
import establishmentReducer from "./features/establishments/reducer";
import orderReducer from "./features/orders/reducer";
import addonReducer from "./features/addons/reducer";
import campaignReducer from "./features/campaign/reducer";
import champReducer from "./features/champ/reducer";
import categoryReducer from "./features/categories/reducer";
import customerReducer from "./features/Customer/reducer";
import reviewReducer from "./features/review/reducer";
import couponReducer from "./features/coupon/reducer";
import withdrawalReducer from "./features/withdrawalRequests/reducer";
import chatReducer from "./features/chat/reducer";
import tabrequestReducer from "./features/TabRequest/reducer";
import dashboardReducer from "./features/dashboard/reducer";
import businessSetupReducer from "./features/BusinessSetup/reducer";
import employeeReducer from "./features/employee/reducer";

const rootReducer = {
  user: userReducer,
  item: itemReducer,
  establishment: establishmentReducer,
  category: categoryReducer,
  order: orderReducer,
  champ: champReducer,
  addon: addonReducer,
  withdrawal: withdrawalReducer,
  tabrequest: tabrequestReducer,
  campaign: campaignReducer,
  customer: customerReducer,
  review: reviewReducer,
  chat: chatReducer,
  dashboard: dashboardReducer,
  coupon: couponReducer,
  businesssetup: businessSetupReducer,
  employee: employeeReducer,
};

export default rootReducer;
