import { lazy } from "react";

const NotFound = lazy(() => import("../../components/ui/page/NotFound"));
const Dashboard = lazy(() => import("../../pages/Dashboard"));
const Items = lazy(() => import("../../pages/Items"));
const Orders = lazy(() => import("../../pages/Orders"));
const Addons = lazy(() => import("../../pages/Addons"));
const Campaign = lazy(() => import("../../pages/Campaign"));
const TabRequest = lazy(() => import("../../pages/TabRequest"));
const Customers = lazy(() => import("../../pages/Customer"));
const Review = lazy(() => import("../../pages/Review"));
const Chat = lazy(() => import("../../pages/Chat"));
const Coupon = lazy(() => import("../../pages/Coupon"));
const Cuisines = lazy(() => import("../../pages/Cuisines"));
const RideCoupons = lazy(() => import("../../pages/RideCoupons"));
const Expense = lazy(() => import("../../pages/ExpenseReport"));
const ItemReport = lazy(() => import("../../pages/ItemReport"));
const Transaction = lazy(() => import("../../pages/TransactionReport"));
const Order = lazy(() => import("../../pages/OrderReport"));
const WithdrawalRequest = lazy(() => import("../../pages/WithdrawalRequests"));
const Establishment = lazy(() => import("../../pages/Establishment"));
const Employee = lazy(() => import("../../pages/Employees"));
const Categories = lazy(() => import("../../pages/Categories"));
const Champ = lazy(() => import("../../pages/Champ"));
const ChampReport = lazy(() => import("../../pages/ChampReport"));
const EstReport = lazy(() => import("../../pages/EstablishmentReport"));
const Roles = lazy(() => import("../../pages/EmployeeRoles"));
const BusinessSetup = lazy(() => import("../../pages/BusinessSetup"));

const AddEmployee = lazy(
  () => import("../../features/employee/employee-list/createEmployee")
);

const controlPanel = [
  {
    path: "",
    component: Dashboard,
  },
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "employees",
    component: Employee,
  },
  {
    path: "employees/add",
    component: AddEmployee,
  },
  {
    path: "business-setup/*",
    component: BusinessSetup,
  },
  {
    path: "employee-role/*",
    component: Roles,
  },
  {
    path: "establishment-report/*",
    component: EstReport,
  },
  {
    path: "champ-report/*",
    component: ChampReport,
  },
  {
    path: "establishments/*",
    component: Establishment,
  },
  {
    path: "items/*",
    component: Items,
  },
  {
    path: "item-report/*",
    component: ItemReport,
  },
  {
    path: "orders/*",
    component: Orders,
  },
  {
    path: ":page/*",
    component: Coupon,
  },
  {
    path: "add-ons/*",
    component: Addons,
  },
  {
    path: "campaign/*",
    component: Campaign,
  },
  {
    path: "withdrawal-request/*",
    component: WithdrawalRequest,
  },
  {
    path: "tab-requests/*",
    component: TabRequest,
  },
  {
    path: "customers/*",
    component: Customers,
  },
  {
    path: "cuisines/*",
    component: Cuisines,
  },
  {
    path: "ride-coupon/*",
    component: RideCoupons,
  },
  {
    path: "categories/*",
    component: Categories,
  },
  {
    path: "expense-report/*",
    component: Expense,
  },
  {
    path: "transaction-report/*",
    component: Transaction,
  },
  {
    path: "order-report/*",
    component: Order,
  },
  {
    path: "reviews",
    component: Review,
  },
  {
    path: "chat/*",
    component: Chat,
  },
  {
    path: "champ/*",
    component: Champ,
  },
  {
    path: "*",
    component: NotFound,
  },
];

export default controlPanel;
