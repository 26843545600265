/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { CampaignType } from "./types";

const initialStateValue: CampaignType = {
  basic: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  basicLoading: true,
  item: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  hiddenItemCampaign: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  hiddenItemCampaignLoading: true,
  itemLoading: true,
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: { value: initialStateValue },
  reducers: {
    setCampaign: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetCampaign: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setCampaign, resetCampaign } = campaignSlice.actions;

export default campaignSlice.reducer;
