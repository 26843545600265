import { createSlice } from "@reduxjs/toolkit";
import { User } from "./types";

const initialStateValue: User = {
  created_at: "",
  id: "",
  first_name: "",
  last_name: "",
  phone: 0,
  establishment: "",
  total_commission_earning: "",
  digital_received: "",
  manual_received: "",
  delivery_charge: "",
  email: "",
  image: "",
  password: "",
  remember_token: "",
  role_id: 0,
  zone_id: 0,
  updated_at: "",
  loggedIn: false,
  country: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: { value: initialStateValue },
  reducers: {
    login: (state, action) => {
      // Set Token
      sessionStorage.setItem("accessToken", action.payload.access_token);
      if (action.payload.remember_me) {
        localStorage.setItem("accessToken", action.payload.access_token);
      }
      state.value = action.payload;
    },
    setUser: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    logout: (state) => {
      localStorage.clear();
      sessionStorage.clear();
      state.value = initialStateValue;
    },
  },
});

export const { login, setUser, logout } = userSlice.actions;
export default userSlice.reducer;
